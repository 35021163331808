import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getmarketdetails",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getmarketdetails",
        "aria-label": "getmarketdetails permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getMarketDetails`}</h1>
    <p>{`This function computes market details for the passed reserves data. Markets are defined as ETH`}{`<`}{`>`}{`ERC20, ERC20`}{`<`}{`>`}{`ETH, or ERC20`}{`<`}{`>`}{`ERC20 pairs, where the first currency is the input and the second is the output. Reserves must be specified for both the input and output currency.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In the case of ETH, `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` should be passed as the reserves data. `}<Link to='/docs/v1/SDK/data/#getttokenreserves' mdxType="Link"><inlineCode parentName="p">{`getTokenReserves`}</inlineCode></Link>{`formatted ERC20 reserves, or the requisite data can be fetched manually and passed in.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Rates are calculated to 18 decimal places of precision.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "function-signature",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#function-signature",
        "aria-label": "function signature permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Function Signature`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export function getMarketDetails(
  optionalReservesInput: OptionalReserves,
  optionalReservesOutput: OptionalReserves
): MarketDetails
`}</code></pre>
    <h2 {...{
      "id": "input-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#input-parameters",
        "aria-label": "input parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Input Parameters`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`optionalReservesInput`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`OptionalReserves`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Reserves data for the input currency.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`optionalReservesOutput`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`OptionalReserves`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Reserves data for the output currency.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example-usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-usage",
        "aria-label": "example usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const reserves: ChainIdOrProvider = await getTokenReserves(tokenAddress)

const marketDetails: MarketDetails = getMarketDetails(undefined, reserves) // ETH<>ERC20

/*
{
  // market type
  tradeType: 'ETH_TO_TOKEN',

  // dummy ETH reserves
  inputReserves: {
    token: {
      chainId: 1,
      address: 'ETH',
      decimals: 18
    }
  },

  // normalized token reserves
  outputReserves: <NormalizedReserves>,

  // market rate calculated to 18 decimals of precision
  marketRate: {
    rate: <BigNumber>,        // x output / 1 input
    rateInverted: <BigNumber> // x input / 1 output
  }
}
*/
`}</code></pre>
    <h1 {...{
      "id": "gettradedetails",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#gettradedetails",
        "aria-label": "gettradedetails permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getTradeDetails`}</h1>
    <p>{`This function computes trade details for the passed market data.`}</p>
    <p>{`-This function throws an error if the passed `}{`_`}{`tradeAmount is greater than the amount of ETH/tokens in the relevant Uniswap exchange.`}</p>
    <ul>
      <li parentName="ul">{`Trade amounts must be passed in non-decimal form `}{`(`}{`where e.g. 1 ETH is represented as 1000000000000000000 wei`}{`)`}{`.`}</li>
    </ul>
    <h2 {...{
      "id": "function-signature-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#function-signature-1",
        "aria-label": "function signature 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Function Signature`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export function getTradeDetails(
  tradeExact: TRADE_EXACT,
  _tradeAmount: BigNumberish,
  marketDetails: MarketDetails
): TradeDetails
`}</code></pre>
    <h2 {...{
      "id": "input-parameters-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#input-parameters-1",
        "aria-label": "input parameters 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Input Parameters`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tradeExact`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TRADE_EXACT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether either the input or the output currency is the exact amount.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`_`}{`tradeAmount`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`BigNumberish`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount to buy/sell `}{`(`}{`of the output/input currency, depending on tradeExact`}{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`marketDetails`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`MarketDetails`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Market details.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example-usage-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-usage-1",
        "aria-label": "example usage 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const _purchaseAmount: BigNumber = new BigNumber('2.5')
const _decimals: number = 18
const tradeAmount: BigNumber = _purchaseAmount.multipliedBy(10 ** _decimals)
const marketDetails: MarketDetails = getMarketDetails(undefined, reserves) // ETH<>ERC20

// buy exactly 2.5 of an 18 decimal ERC20 with ETH
const tradeDetails: TradeDetails = getTradeDetails(TRADE_EXACT.OUTPUT, tradeAmount, marketDetails)

/*
{
  marketDetailsPre: <MarketDetails>,

  marketDetailsPost: <MarketDetails>,

  tradeType: 'ETH_TO_TOKEN',

  tradeExact: 'OUTPUT',

  inputAmount: {
    token: <Token>,
    amount: <BigNumber>
  },

  outputAmount: {
    token: <Token>,
    amount: <BigNumber>
  },

  // execution rate calculated to 18 decimals of precision
  executionRate: {
    rate: <BigNumber>         // x output / 1 input
    rateInverted: <BigNumber> // x input / 1 output
  },

  // slippage between the pre- and post-trade market rates, in basis points, calculated to 18 decimals of precision
  marketRateSlippage: <BigNumber>,

  // slippage between the execution and pre-trade market rate, in basis points, calculated to 18 decimals of precision
  executionRateSlippage: <BigNumber>
}
*/
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      